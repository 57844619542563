<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

  * {
    font-family: 'Open Sans', sans-serif;
  }

  input:focus-visible {
    outline: 0;
  }

  body {
   
  }

  .chat-wrapper {
    position: absolute;
    width: 390px;
    padding: 20px;
    right: 25px;
    bottom: 10%;
  }

  .chat-box, .chat-box-alt {
    width: 350px;
    height: 500px;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 1px 1px 10px 0px #adadad;
  }


  .chat-header {
    display: flex;
    justify-content: space-between;
  }

    .chat-header span {
      background-color: #fff;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 1px 4px 20px rgba(0, 0, 0, 0.15);
    }

  .chat-body-alt {
    flex-grow: 1;
  }

    .chat-body-alt .get-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

      .chat-body-alt .get-info img {
        margin-bottom: 20px;
      }

      .chat-body-alt .get-info input {
        border: 1px solid #0072b3;
        padding: 5px 10px;
        border-radius: 8px;
      }

      .chat-body-alt .get-info .form-group {
        margin-bottom: 10px;
      }

        .chat-body-alt .get-info .form-group span {
          display: block;
          padding-top: 3px;
          padding-left: 5px;
        }

      .chat-body-alt .get-info .btn {
        margin-top: 15px;
        border: 1px solid #0072b3;
        background-color: #0072b3;
        padding: 8px 15px;
        color: #fff;
        border-radius: 8px;
        cursor: pointer;
      }

  .chat-body {
    display: flex;
    height: 100%;
    padding: 20px 0;
    position: relative;
    height: 390px;
  }

    .chat-body ul {
      list-style-type: none;
      padding: 0 10px;
      margin: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 390px;
      overflow: auto;
      position: absolute;
    }

      .chat-body ul div {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
      }

      .chat-body ul li.client {
        background-color: #50B0DD;
        padding: 18px 24px;
        border-radius: 30px 0px 30px 30px;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
        color: #fff;
        align-self: flex-end;
        margin: 5px 0;
      }

      .chat-body ul li.user {
        background-color: #EFEFEF;
        padding: 18px 24px;
        border-radius: 0px 30px 30px 30px;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
        align-self: flex-start;
        margin: 5px 0;
      }

      .chat-body ul li.machine {
        border: 1px solid #50B0DD;
        padding: 10px 15px;
        border-radius: 30px;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
        color: #0072B3;
        align-self: flex-end;
        margin: 5px 0;
      }

      .chat-body ul li p {
        margin: 0;
      }


  .chat-footer {
    display: flex;
  }

    .chat-footer input {
      background-color: #EFEFEF;
      border-radius: 30px;
      border: none;
      padding: 8px 15px;
      flex-grow: 1;
    }

    .chat-footer span {
      background-color: #0072B3;
      border-radius: 50%;
      border: none;
      padding: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }

      .chat-footer span i {
        color: #fff;
      }

  .chat-btn {
    position: relative;
    right: 58px;
    float: right;
    top: 23px;
  }

    .chat-btn svg {
      position: absolute;
    }

      .chat-btn svg.shorter-icon {
        top: 5px;
        left: 5px;
      }

  .CstmScrollbar::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  .CstmScrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
  }
  /* Handle */
  .CstmScrollbar::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 10px;
  }
    /* Handle on hover */
    .CstmScrollbar::-webkit-scrollbar-thumb:hover {
      background: #cdcccc;
    }

  #chatBox {
    display: none;
  }

  .error {
    font-size: 12px;
    color: red;
  }
  .dot-elastic {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #0072b3;
    color: #0072b3;
    animation: dot-elastic 1s infinite linear;
    padding: 0 !important;
  }

    .dot-elastic::before, .dot-elastic::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
    }

    .dot-elastic::before {
      left: -15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #158bcf;
      color: #158bcf;
      animation: dot-elastic-before 1s infinite linear;
    }

    .dot-elastic::after {
      left: 15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #014f7a;
      color: #014f7a;
      animation: dot-elastic-after 1s infinite linear;
    }

  @keyframes dot-elastic-before {
    0% {
      transform: scale(1, 1);
    }

    25% {
      transform: scale(1, 1.5);
    }

    50% {
      transform: scale(1, 0.67);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes dot-elastic {
    0% {
      transform: scale(1, 1);
    }

    25% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1, 1.5);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes dot-elastic-after {
    0% {
      transform: scale(1, 1);
    }

    25% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1, 0.67);
    }

    75% {
      transform: scale(1, 1.5);
    }

    100% {
      transform: scale(1, 1);
    }
  }
  .pop-wrapper {
    background-color: rgba(0,0,0,0.5);
    inset: 0;
    position: absolute;
    margin: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .popup-end {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #efefef;
    padding: 15px;
    width: 100%;
    margin: 0 34px;
    text-align: center;
  }
  .blue-btn {
    background-color: #0076ba;
    color: #fff;
    padding: 10px;
    border: 0;
    border-radius: 8px;
    margin: 0 5px;
    min-width:100px;
  }
  .red-btn {
    background-color: #900C3F;
    color: #fff;
    padding: 10px;
    border: 0;
    border-radius: 8px;
    margin: 0 5px;
    min-width: 100px;
  }
</style>

<div class="chat-wrapper">
  <div *ngIf="MainChatBox" class="chat-box-main">
    <div *ngIf="MainChat" class="chat-box">
      <div class="chat-header">
        <i></i>
        <span (click)="CloseChatPop();"><i class="fa-light fa-xmark"></i></span>
      </div>
      <div class="chat-body">
        <ul class="CstmScrollbar">
          <li class="user">Hello, How can we help?</li>
          <div *ngFor="let chattext of ChatData">
          <li class="client">{{chattext.question}}</li>
          <li *ngIf="chattext.agent_answer?.length" class="user">{{chattext.agent_answer}}</li>
          <li *ngIf="!chattext.agent_answer?.length" class="user loading">
            <div class="dot-elastic"></div>
          </li>
      </div>
      <li (click)="Checkme($event)" *ngIf="Machine" class="machine">What type of talent do you specialize in recruiting and placing?</li>
      <li (click)="Checkme($event)" *ngIf="Machine" class="machine">How can I learn more about your company and its services?</li>
      <li (click)="Checkme($event)" *ngIf="Machine" class="machine">How can I get in touch with a representative to discuss my specific needs?</li>

      </ul>
    </div>

    <div class="chat-footer">
      <input [(ngModel)]="MessageValue" type="text" placeholder="type your message..." />
      <span (click)="SendSMS()"><i class="fa-duotone fa-paper-plane"></i></span>
    </div>

  </div>


  <div *ngIf="UserInfo" class="chat-box">
    <div class="chat-header">
      <span (click)="InfoChatPop();"><i class="fa-light fa-info"></i></span>
      <span (click)="CloseChatPop();"><i class="fa-light fa-xmark"></i></span>
    </div>
    <div class="chat-body-alt">

      <div class="get-info">
        <h4 style="max-width: 300px; color: #082963; text-align:center; font-weight: 400; margin-top:0;">Please fill out the details below to start the chat!</h4>
        <img src="https://remotescouts.com/wp-content/uploads/2023/09/RS.png" alt="Logo" />
        <div class="form-group">
          <input [(ngModel)]="NameValue" id="nameF" type="text" placeholder="Your Name" />
          <span *ngIf="NameError" class="error">Please enter your name</span>
        </div>
        <div class="form-group">
          <input [(ngModel)]="EmailValue" id="emailF" type="email" placeholder="Your Email" />
          <span *ngIf="emailError" class="error">Please enter correct email address</span>
        </div>

        <button (click)="CallApi()" type="button" id="ajaxBtn" class="btn">Submit</button>
      </div>
    </div>
  </div>
  <div *ngIf="Popup" class="pop-wrapper">
    <div class="popup-end">
      <p>Do you want to end chat session?</p>
      <div>
        <button (click)="ClosePop();" class="blue-btn">No</button>
        <button (click)="CloseChat();" class="red-btn">Yes</button>
      </div>
    </div>
  </div>
  <div *ngIf="PopupInfo" class="pop-wrapper">
    <div class="popup-end">
      <p><strong>Disclaimer:</strong><br/> By using our chatbot, you consent to the recording of chat sessions and collection and use of your data in accordance with our Privacy Policy. If you have any concerns about your data, please refer to our Privacy Policy or contact us for more information.</p>
      <div>
        <button (click)="InfoChatPop();" class="blue-btn">Close</button>
      </div>
    </div>
  </div>
  
</div>
  <div (click)="StartFormAgain();" class="chat-btn">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <mask id="mask0_29_281" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="3" width="55" height="54">
        <path d="M5.31891 30.0452C5.31891 16.3178 16.4465 5.19014 30.174 5.19014L55.029 5.19014L55.0291 30.0452C55.0291 43.7727 43.9014 54.9003 30.174 54.9003C16.4465 54.9003 5.31892 43.7727 5.31891 30.0452Z" fill="white" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />

      </mask>
      <g mask="url(#mask0_29_281)">
        <path d="M60 59.8713L0.347832 59.8713L0.347827 0.219133L60 0.219128L60 59.8713Z" fill="#EFEFEF" />
      </g>
    </svg>
    <svg class="shorter-icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <mask id="mask0_29_290" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="2" width="46" height="46">
        <path d="M4.70506 25.0452C4.70506 13.7403 13.869 4.57637 25.1739 4.57636L45.6428 4.57636L45.6428 25.0452C45.6428 36.3502 36.4789 45.5141 25.1739 45.5141C13.869 45.5141 4.70506 36.3502 4.70506 25.0452Z" fill="white" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M35.4084 31.1859L16.9865 31.1859M35.4084 22.9984L16.9865 22.9984M35.4084 14.8108L25.174 14.8108" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
      </mask>
      <g mask="url(#mask0_29_290)">
        <path d="M49.7366 49.6079L0.611259 49.6079L0.611255 0.482579L49.7366 0.482574L49.7366 49.6079Z" fill="#0072B3" />
      </g>
    </svg>
  </div>
</div>

<router-outlet></router-outlet>
