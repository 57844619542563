import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Call } from '@angular/compiler';
import { ApiCallService } from './api-call.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
  NameValue: any;
  MessageValue: any;
  EmailValue: any = "";
  startQuestion: any;
  UserInfo: boolean = true;
  NameError: boolean = false;
  emailError: boolean = false;
  MainChat: boolean = false;
  MainChatBox: boolean = false;
  Popup: boolean = false;
  PopupInfo: boolean = false;
  Machine: boolean = true;
  ChatData: any;
  constructor(private http: HttpClient, private apiCall: ApiCallService) { }

  CallApi() {
    if (this.NameValue == "" || this.NameValue == null) {
      this.NameError = true;
    }
    else if (this.EmailValue == "" && !this.EmailValue.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")) {
      this.emailError = true;
    }
    else { 
    const data = {
      name: this.NameValue,
      email: this.EmailValue
    };
    this.apiCall.postAPI(data).subscribe(
      (response) => {
        console.log("Register User", response);
        this.UserInfo = false;
        this.MainChat = true;
      },
      (Error) => {
        console.log("Register User", Error);
      }
    );
    }
  };
  Checkme(value: any) {
    console.log(value.target.innerText);
    this.Machine = false;
    this.startQuestion = value.target.innerText;
    if (this.startQuestion != null || this.startQuestion != "") {
      this.MessageValue = this.startQuestion
    }
   this.SendSMS();
  }
  SendSMS() {
    
    this.Machine = false;
    const dataMessage = {
      user_id: this.NameValue,
      question: this.MessageValue
    };
    this.apiCall.postMessage(dataMessage).subscribe(
      (response) => {
        console.log("Post Message", response);
        this.SmsHistoryTime();
        this.MessageValue = "";
      },
      (Error) => {
        console.log("Post Message", Error);
      }
    );
  }
  CloseChat() {
    this.UserInfo = false;
    this.MainChat = false;
    this.MainChatBox = false;
    this.Popup = false;
  }
  StartFormAgain() {
    if (this.MainChatBox == true) {
      this.MainChatBox = false;
    }
    else {
      this.MainChatBox = true;
      if (this.MainChat == true) {
        this.UserInfo = false;
        this.MainChat = true
      }
      else {
        this.MainChat = false;
        this.UserInfo = true
      }
    }
  }
  SmsHistoryTime() {

    setInterval(() => {
      this.SmsHistory();
    }, 1000);
  }
  SmsHistory() {
    const SmsHstoryData = {
      user_id: this.NameValue,
    };
    this.apiCall.SmsHistoryG(SmsHstoryData).subscribe(
      (response) => {
        console.log("History Message", response);
        this.ChatData = response;
      },
      (Error) => {
        console.log("History Message", Error);
      }
    );
  }
  ClosePop() {
    this.Popup = false;
  }
  CloseChatPop() {
    this.Popup = true;
  }
  InfoChatPop() {
    if (this.PopupInfo == true) {
      this.PopupInfo = false;
    }
    else {
      this.PopupInfo = true;
    }
  }
  ngOnInit() {
    window.setTimeout(() => {
      this.MainChatBox = true
    }, 12000);
  }
}





