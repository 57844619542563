import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
  
export class ApiCallService {
   /*body = new HttpParams().set('name', "Saad").set('email', "shassan@gmail.com");*/
  constructor(private http: HttpClient) { }
  
  postAPI(data: any) {
    
    var body = new HttpParams().set('name', data.name).set('email', data.email);
    return this.http.post('https://alt.collectco.com/register', body);
  }
  postMessage(dataMessage: any) {
    var sendMessage = new HttpParams().set('user_id', dataMessage.user_id).set('question', dataMessage.question);
    return this.http.post('https://alt.collectco.com/send_message?', sendMessage);
  }
  SmsHistoryG(SmsHstoryData: any) {
    var sendMessage = new HttpParams().set('user_id', SmsHstoryData.user_id);
    return this.http.get('https://alt.collectco.com/chat_history?' + sendMessage);
  }
}
